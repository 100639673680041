<template>
  <v-container fluid class="mt-4">
    <v-row>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        class="mb-3"
        color="#B361FF"
        :grow="this.$vuetify.breakpoint.mobile"
        :right="!this.$vuetify.breakpoint.mobile"
      >
        <v-tab> Wszystkie </v-tab>
        <v-tab>
          <v-icon
            :elevation="'1'"
            :style="'color: rgb(255, 63, 234); font-size: 27px !important;'"
            >fas fa-heart mr-3</v-icon
          >
          Ulubione
          {{
            this.trainings_liked.length > 0
              ? `(${this.trainings_liked.length})`
              : ""
          }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        class="bg-transparent transparent"
        :style="'width: 100% !important;'"
      >
        <v-tab-item>
          <v-container fluid>
            <v-row :class="`${$vuetify.breakpoint.mdAndUp ? 'mt-2 ml-2' : ''}`">
              <v-col cols="2" md="3" v-if="$vuetify.breakpoint.mdAndUp">
                <v-row>
                  <v-checkbox
                    v-model="selected.free"
                    v-bind:label="`Tylko darmowe (${
                      this.free.length > 0 ? this.free[0].count : 0
                    })`"
                    :value="'free'"
                    class="mt-0 free-checkbox text-typo"
                    v-if="!userHasActivePlan"
                  ></v-checkbox>
                </v-row>
                <v-row class="mt-0">
                  <v-checkbox
                    v-model="selected.recommended"
                    v-bind:label="`Tylko polecane dla mnie (${
                      this.recommended.length > 0
                        ? this.recommended[0].count
                        : 0
                    })`"
                    :value="'recommended'"
                    class="mt-0 recommended-checkbox text-typo"
                  ></v-checkbox>
                </v-row>
                <v-row>
                  <v-col v-if="!filtersEmpty">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      @click="resetFilters()"
                      class="
                        font-weight-bold
                        btn-default
                        bg-gradient-info
                        py-2
                        px-6
                        me-2
                        mt-1
                        mb-2
                      "
                      color="#5e72e4"
                      ><v-icon>fas fa-redo mr-3</v-icon> Resetuj
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row>
                  <h4 class="text-typo">Kategorie</h4>
                </v-row>
                <v-row v-for="category in categories" :key="category.value">
                  <v-checkbox
                    v-model="selected.categories"
                    v-bind:label="`${category.label} (${category.count})`"
                    :value="category.value"
                    class="mt-0"
                  ></v-checkbox>
                </v-row>
                <v-row>
                  <h4 class="text-typo">Typy treningów</h4>
                </v-row>
                <v-row
                  v-for="(type, index) in types"
                  :key="`${type.value}_${index}`"
                >
                  <v-checkbox
                    v-model="selected.types"
                    v-bind:label="`${type.label} (${type.count})`"
                    :value="type.value"
                    class="mt-0"
                  ></v-checkbox>
                </v-row>

                <v-row>
                  <h4 class="text-typo">Długość</h4>
                </v-row>
                <v-row
                  v-for="(length, index) in lengths"
                  :key="`${length.value}_${index}`"
                >
                  <v-checkbox
                    v-model="selected.lengths"
                    v-bind:label="`${length.label} (${length.count})`"
                    :value="length.value"
                    class="mt-0"
                  ></v-checkbox>
                </v-row>
                <v-row>
                  <h4 class="text-typo">Poziom trudności</h4>
                </v-row>
                <v-row
                  v-for="(diff, index) in difficulty"
                  :key="`${diff.value}_${index}`"
                >
                  <v-checkbox
                    v-model="selected.difficulty"
                    :value="diff.value"
                    class="mt-0"
                  >
                    <template v-slot:label>
                      <DifficultyStars
                        :tooltip="true"
                        :rating="diff.value"
                      ></DifficultyStars>
                    </template>
                  </v-checkbox>
                </v-row>

                <v-row>
                  <h4 class="text-typo">Części ciała</h4>
                </v-row>
                <v-row
                  v-for="(bp, index) in body_parts"
                  :key="`${bp.value}_${index}`"
                >
                  <v-checkbox
                    v-model="selected.body_parts"
                    v-bind:label="`${bp.label} (${bp.count})`"
                    :value="bp.value"
                    class="mt-0"
                  ></v-checkbox>
                </v-row>
                <v-row>
                  <h4 class="text-typo">Sprzęt</h4>
                </v-row>
                <v-row
                  v-for="(tool, index) in tools"
                  :key="`${tool.value}_${index}`"
                >
                  <v-checkbox
                    v-model="selected.tools"
                    v-bind:label="`${tool.label} (${tool.count})`"
                    :value="tool.value"
                    class="mt-0"
                  ></v-checkbox>
                </v-row>
              </v-col>
              <v-col v-else cols="12">
                <v-row>
                  <v-col cols="4" sm="3" md="2">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      @click="filterDialog = true"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        bg-gradient-primary
                        py-2
                        px-6
                        me-2
                        mt-1
                        mb-2
                      "
                      color="#5e72e4"
                      ><v-icon>fas fa-filter</v-icon>
                      <span class="ml-1">Filtry</span>
                    </v-btn>
                  </v-col>
                  <v-col v-if="!filtersEmpty">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      @click="resetFilters()"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        bg-gradient-info
                        py-2
                        px-6
                        me-2
                        mt-1
                        mb-2
                      "
                      color="#5e72e4"
                      ><v-icon>fas fa-redo</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" lg="9" md="9">
                <v-row>
                  <v-col>
                    <h4 class="text-typo">
                      {{ this.trainings.length }} wyniki
                    </h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    v-for="training in trainings.slice(page * 9, page * 9 + 9)"
                    :key="training.resourceid"
                  >
                    <v-card
                      class="transparent pa-1 thumbnail-container"
                      @click="openTraining(training.resourceid)"
                    >
                      <v-img
                        :src="training.thumbnail.url"
                        elevation="1"
                        class="rounded thumbnail-static"
                      >
                        <DifficultyStars
                          :color="'white'"
                          class="training-diff"
                          :rating="training.difficulty[0]"
                        ></DifficultyStars>
                        <template v-slot:placeholder>
                          <v-sheet :color="`grey lighten-4`">
                            <v-skeleton-loader type="image"></v-skeleton-loader>
                          </v-sheet>
                        </template>
                      </v-img>
                      <div class="training-liked" v-if="training.isLiked">
                        <v-icon
                          :elevation="'1'"
                          :style="'color: rgb(255, 63, 234); font-size: 35px !important;'"
                          >fas fa-heart</v-icon
                        >
                      </div>

                      <h4 class="text-typo">
                        {{ training.name }}
                        <v-chip
                          class="ma-2"
                          x-small
                          color="green"
                          text-color="white"
                          v-if="!userHasActivePlan && training.is_free"
                        >
                          FREE
                        </v-chip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              class="ma-2"
                              x-small
                              color="#e530ff"
                              text-color="white"
                              v-if="training.isRecommended"
                            >
                              Polecany
                            </v-chip>
                          </template>
                          <span
                            >Odpowiada twoim celom oraz poziomowi
                            zaawansowania</span
                          >
                        </v-tooltip>
                      </h4>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col
                    cols="12"
                    class="d-inline-flex justify-center align-center"
                  >
                    <v-btn
                      class="btn-primary button-primary-gradient"
                      :disabled="page == 0"
                      @click="prevPage()"
                    >
                      <v-icon :style="'color: white;'" class="ma-2">
                        fas fa-chevron-left
                      </v-icon>
                    </v-btn>
                    <h4 class="text-typo mx-2">
                      strona {{ page + 1 }}/{{ maxPage }}
                    </h4>
                    <v-btn
                      class="btn-primary button-primary-gradient"
                      :disabled="page >= maxPage - 1"
                      @click="nextPage()"
                    >
                      <v-icon :style="'color: white;'" class="ma-2">
                        fas fa-chevron-right
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col>
                    <h4 class="text-typo">
                      {{ this.trainings_liked.length }} wyniki
                    </h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    lg="4"
                    v-for="training in trainings_liked"
                    :key="training.resourceid"
                  >
                    <v-card
                      class="transparent pa-1 thumbnail-container"
                      @click="openTraining(training.resourceid)"
                    >
                      <v-img
                        :src="training.thumbnail.url"
                        elevation="1"
                        class="rounded thumbnail-static"
                      >
                      </v-img>
                      <v-img
                        v-if="false"
                        :src="training.video_gif.url"
                        elevation="1"
                        class="rounded thumbnail-gif"
                      >
                      </v-img>
                      <DifficultyStars
                        :color="'white'"
                        class="training-diff"
                        :rating="training.difficulty[0]"
                      ></DifficultyStars>
                      <h4 class="text-typo">
                        {{ training.name }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              class="ma-2"
                              x-small
                              color="#e530ff"
                              text-color="white"
                              v-if="training.isRecommended"
                            >
                              Polecany
                            </v-chip>
                          </template>
                          <span
                            >Odpowiada twoim celom oraz poziomowi
                            zaawansowania</span
                          >
                        </v-tooltip>
                      </h4>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-row>
    <v-dialog
      v-model="filterDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="400"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="filterDialog = false">
            <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                ? "fas fa-chevron-left"
                : "fas fa-times"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
            >Filtruj treningi</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container class="p-4">
          <v-row>
            <v-container fluid>
              <v-checkbox
                v-model="selected.free"
                v-bind:label="`Tylko darmowe (${
                  this.free.length > 0 ? this.free[0].count : 0
                })`"
                :value="'free'"
                class="mt-0 free-checkbox text-typo"
                v-if="!userHasActivePlan"
              ></v-checkbox>
            </v-container>
          </v-row>
          <v-row class="mt-0">
            <v-container fluid class="mt-0 pt-0">
              <v-checkbox
                v-model="selected.recommended"
                v-bind:label="`Tylko polecane dla mnie (${
                  this.recommended.length > 0 ? this.recommended[0].count : 0
                })`"
                :value="'recommended'"
                class="mt-0 recommended-checkbox text-typo"
              ></v-checkbox>
            </v-container>
          </v-row>
          <v-row>
            <v-container fluid>
              <h4 class="text-typo">Kategorie</h4>
              <v-checkbox
                v-for="(category, index) in categories"
                :key="index"
                v-model="selected.categories"
                v-bind:label="`${category.label} (${category.count})`"
                :value="category.value"
                class="mt-0"
              ></v-checkbox>
            </v-container>
          </v-row>
          <v-row>
            <v-container fluid>
              <h4 class="text-typo">Typy treningów</h4>
              <v-checkbox
                v-for="(type, index) in types"
                :key="index"
                v-model="selected.types"
                v-bind:label="`${type.label} (${type.count})`"
                :value="type.value"
                class="mt-0"
              ></v-checkbox>
            </v-container>
          </v-row>

          <v-row>
            <v-container fluid>
              <h4 class="text-typo">Długość</h4>
              <v-checkbox
                v-for="(length, index) in lengths"
                :key="index"
                v-model="selected.lengths"
                v-bind:label="`${length.label} (${length.count})`"
                :value="length.value"
                class="mt-0"
              ></v-checkbox>
            </v-container>
          </v-row>

          <v-row>
            <v-container fluid>
              <h4 class="text-typo">Poziom trudności</h4>
              <v-checkbox
                v-for="(diff, index) in difficulty"
                :key="index"
                v-model="selected.difficulty"
                :value="diff.value"
                class="mt-0"
              >
                <template v-slot:label>
                  <DifficultyStars
                    :tooltip="true"
                    :rating="diff.value"
                  ></DifficultyStars>
                </template>
              </v-checkbox>
            </v-container>
          </v-row>

          <v-row>
            <v-container fluid>
              <h4 class="text-typo">Części ciała</h4>
              <v-checkbox
                v-for="(bp, index) in body_parts"
                :key="index"
                v-model="selected.body_parts"
                v-bind:label="`${bp.label} (${bp.count})`"
                :value="bp.value"
                class="mt-0"
              ></v-checkbox>
            </v-container>
          </v-row>

          <v-row>
            <v-container fluid>
              <h4 class="text-typo">Sprzęt</h4>
              <v-checkbox
                v-for="(tool, index) in tools"
                :key="index"
                v-model="selected.tools"
                v-bind:label="`${tool.label} (${tool.count})`"
                :value="tool.value"
                class="mt-0"
              ></v-checkbox>
            </v-container>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-gradient-primary
                  py-2
                  px-6
                  me-2
                  mt-0
                  mb-2
                  w-100
                "
                color="#5e72e4"
                @click="filterDialog = false"
                >Filtruj</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="trainingDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      hide-overlay
      width="800"
      :transition="
        this.$vuetify.breakpoint.mobile
          ? 'slide-x-transition'
          : 'dialog-bottom-transition'
      "
    >
      <v-card>
        <v-toolbar class="bg-gradient-primary">
          <v-btn icon @click="trainingDialog = false">
            <v-icon color="white">{{
              this.$vuetify.breakpoint.mobile
                ? "fas fa-chevron-left"
                : "fas fa-times"
            }}</v-icon>
          </v-btn>
          <v-toolbar-title :style="'color: white;'"
            >{{ currentTraining.name }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-container fluid class="p-1">
          <v-row>
            <v-col cols="12">
              <div style="padding: 56.25% 0 0 0; position: relative">
                <iframe
                  v-if="currentTraining"
                  class="rounded"
                  :src="`https://player.vimeo.com/video/${currentTraining.vimeo_id}?h=401fac24f8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  "
                  title=""
                ></iframe>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="d-flex justify-start pt-0">
              <v-btn
                v-if="currentTraining.isLiked"
                :loading="likeBtnLoading"
                @click="like(currentTraining.resourceid, false)"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-6
                  me-2
                  mt-1
                  mb-1
                "
                color="#5e72e4"
                ><v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
                  >fas fa-heart mr-3</v-icon
                >
                Polubione
              </v-btn>
              <v-btn
                v-else
                :loading="likeBtnLoading"
                @click="like(currentTraining.resourceid)"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  transparent
                  text-typo
                  px-6
                  me-2
                  mt-1
                  mb-1
                "
                color="#5e72e4"
                ><v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
                  >far fa-heart mr-3</v-icon
                >
                Polub
              </v-btn>
            </v-col>

            <v-col class="d-flex justify-end align-center pt-0 mr-4">
              <DifficultyStars
                v-if="currentTraining"
                :tooltip="true"
                :color="'#FF32C6'"
                :rating="currentTraining.difficulty[0]"
              ></DifficultyStars>
            </v-col>
          </v-row>
          <v-row v-if="userHasActivePlan">
            <v-col class="d-flex justify-start mt-0 pt-0">
              <v-btn
                v-if="
                  !diaryDay ||
                  diaryDay.trainings.find(
                    (e) => e.id === currentTraining.resourceid
                  ) === undefined
                "
                :loading="diaryLoading"
                @click="pushDiary()"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold transparent text-typo px-6 me-2 mb-1"
                color="#5e72e4"
                ><v-icon
                  :elevation="'1'"
                  :style="'color: rgb(255, 63, 234); font-size: 25px !important;'"
                  >far fa-calendar-plus mr-3</v-icon
                >
                Wybieram
              </v-btn>
              <v-btn
                v-else-if="diaryDay"
                elevation="0"
                :ripple="false"
                height="43"
                :style="'background-color: rgb(255, 50, 198);'"
                class="
                  font-weight-bold
                  text-white
                  btn-primary
                  px-6
                  me-2
                  mt-1
                  mb-1
                "
                ><v-icon
                  :elevation="'1'"
                  :style="'color: white; font-size: 21px !important;'"
                  >fas fa-heartbeat mr-1</v-icon
                >
                Wykonany
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert
                  dense
                  class="mt-2"
                  :style="'background-color: #EFDFFA;'"
                >
                  <span :style="'color: black'">
                    {{ currentTraining.description }}
                  </span>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert
                  dense
                  class="mt-2"
                  :style="'background-color: #EFDFFA;'"
                >
                  <h4 class="text-typo text-black">
                    <v-icon>fas fa-futbol mr-2</v-icon> Sprzęt
                  </h4>
                  <span :style="'color: black'">
                    <ul>
                      <li v-for="tool in currentTraining.tools" :key="tool">
                        {{
                          tools.find((tl) => {
                            return tl.value == tool;
                          }).label
                        }}
                      </li>
                    </ul>
                  </span>
                </v-alert>
              </v-card>
            </v-col>
            <v-col class="pt-0">
              <v-card class="p-1">
                <v-alert
                  dense
                  class="mt-2"
                  :style="'background-color: #EFDFFA;'"
                >
                  <h4 class="text-typo text-black">
                    <v-icon>fas fa-child mr-2</v-icon> Części ciała
                  </h4>
                  <span :style="'color: black'">
                    <ul>
                      <li v-for="bp in currentTraining.body_parts" :key="bp">
                        {{
                          body_parts.find((part) => {
                            return part.value == bp;
                          }).label
                        }}
                      </li>
                    </ul>
                  </span>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-overlay :opacity="1" :style="'z-index: 999999999;'" :value="pageLoading">
      <v-progress-circular indeterminate size="120" color="#ff00d9">
      </v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import DifficultyStars from "@/components/util/DifficultyStars.vue";
export default {
  name: "Trainings",
  data: function () {
    return {
      pageLoading: true,
      likeBtnLoading: false,
      diaryLoading: false,
      tab: null,
      filterDialog: false,
      trainingDialog: false,
      currentTraining: false,
      free: [],
      recommended: [],
      categories: [],
      types: [],
      lengths: [],
      difficulty: [],
      body_parts: [],
      tools: [],
      trainings: [],
      trainings_liked: [],
      all_trainings: [],
      loading: true,
      selected: {
        free: [],
        recommended: [],
        categories: [],
        types: [],
        lengths: [],
        difficulty: [],
        body_parts: [],
        tools: [],
      },
      page: 0,
      maxPage: 0,
    };
  },
  components: {
    DifficultyStars,
  },
  mounted() {
    this.loadFilters();
    this.loadTrainings();
    this.loadLiked();
  },
  watch: {
    selected: {
      handler: function () {
        this.loadFilters();
        this.loadTrainings();
      },
      deep: true,
    },
    trainingDialog: {
      handler: function () {
        if (!this.trainingDialog) {
          this.currentTraining = false;
        }
      },
    },
  },
  methods: {
    pushDiary() {
      this.diaryLoading = true;
      this.$axios
        .post("/api/diary/addtraining", {
          resourceid: this.currentTraining.resourceid,
          day:this.$getDate()
        })
        .then(async (response) => {
          this.diaryLoading = false;
          if (response.data.success) {
            await this.$store.dispatch("fetchUser");
            this.$toast.open({
              message: `<span class="font-weight-bold">Dodano do dzienniczka!</span>`,
              type: "success",
              duration: 3000,
              position: "bottom",
            });
          } else {
            this.$toast.open({
              message: `<span class="font-weight-bold">Już dodane do dzienniczka!</span>`,
              type: "success",
              duration: 3000,
              position: "bottom",
            });
          }
        })
        .catch(function (error) {
          this.diaryLoading = false;

          console.log(error);
        });
    },
    goToTab() {
      console.log(this.tab);
    },
    openTraining(id) {
      let chosenTraining = this.all_trainings.find((training) => {
        return training.resourceid === id;
      });

      if (
        (chosenTraining.is_free || this.userHasActivePlan) &&
        !this.$store.getters.contentLockedDialog
      ) {
        this.currentTraining = chosenTraining;
        this.trainingDialog = true;
      } else if (!this.trainingDialog) {
        this.$store.commit("setContentLockedDialog", true);
      }
    },
    closeTraining() {
      this.currentTraining = false;
      this.trainingDialog = false;
    },
    resetFilters: function () {
      this.selected = {
        free: [],
        recommended: [],
        categories: [],
        types: [],
        lengths: [],
        difficulty: [],
        body_parts: [],
        tools: [],
      };
    },
    loadFilters: function () {
      this.$axios
        .post("/api/trainings/filters", {
          params: this.$_.omit(this.selected, "types"),
        })
        .then((response) => {
          this.free = response.data.free;
          this.recommended = response.data.recommended;
          this.categories = response.data.categories;
          this.types = response.data.types;
          this.lengths = response.data.lengths;
          this.difficulty = response.data.difficulty;
          this.body_parts = response.data.body_parts;
          this.tools = response.data.tools;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadTrainings: function () {
      this.$axios
        .post("/api/trainings/filtered", {
          params: this.selected,
        })
        .then((response) => {
          this.trainings = response.data.filtered;
          this.loadPages(response.data.filtered.length);
          this.all_trainings = response.data.all;
          this.pageLoading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadLiked: function () {
      this.$axios
        .post("/api/trainings/liked", {
          params: this.selected,
        })
        .then((response) => {
          this.trainings_liked = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    like: function (resourceid, like = true) {
      this.likeBtnLoading = true;
      this.$axios
        .post(`/api/trainings/${like ? "like" : "unlike"}`, {
          resourceid: resourceid,
        })
        .then((response) => {
          if (response.data.success) {
            this.$set(this.currentTraining, "isLiked", like);
            this.likeBtnLoading = false;
            this.loadTrainings();
            this.loadLiked();
          }
        })
        .catch(function (error) {
          this.likeBtnLoading = false;
          console.log(error);
        });
    },
    loadPages: function (count) {
      this.page = 0;
      this.maxPage = Math.ceil(count / 9);
    },
    prevPage: function () {
      if (this.page > 0) {
        this.page -= 1;
      }
    },
    nextPage: function () {
      if (this.page < this.maxPage - 1) {
        this.page += 1;
      }
    },
  },
  computed: {
    filtersEmpty() {
      return (
        this.selected.categories.length == 0 &&
        this.selected.types.length == 0 &&
        this.selected.lengths.length == 0 &&
        this.selected.difficulty.length == 0 &&
        this.selected.body_parts.length == 0 &&
        this.selected.tools.length == 0 &&
        this.selected.free.length == 0 &&
        this.selected.recommended.length == 0
      );
    },
    user() {
      return this.$store.state.user;
    },
    userHasActivePlan() {
      return this.$store.state.userHasActivePlan;
    },
    diaryDay() {
      return this.$store.state.user.diaryDay;
    },
  },
};
</script>

<style>
.free-checkbox .v-label {
  font-weight: bold;
  font-size: 15px;
  color: green !important;
}

.recommended-checkbox .v-label {
  font-weight: bold;
  font-size: 15px;
  color: rgb(229, 48, 255) !important;
}

.training-liked {
  position: absolute !important;
  top: 4px;
  right: 8px;
}

.training-diff {
  position: absolute !important;
  top: 4px;
  left: 8px;
}

/* .thumbnail-container .thumbnail-static {
  display: block;
}

.thumbnail-container .thumbnail-gif {
  display: none;
}

.thumbnail-container:hover .thumbnail-static {
  display: none;
}

.thumbnail-container:hover .thumbnail-gif {
  display: block;
} */
</style>
